//==============================================================================
// This module helps persist the currently active catalog.
//
// Microsoft relies on URLs to keep track of the current catalog, which is fragile.
// Links to My Account and hard-coded or relative internal links will cause
// the catalog ID to be dropped.
//
// This module saves the current catalog into a session cookie so it will be
// used even if the URL isn't set.
//==============================================================================
import * as React from 'react';

import { ICatalogCookieData } from './catalog-cookie.data';
import { ICatalogCookieProps } from './catalog-cookie.props.autogenerated';
import { LogLevel } from '@msdyn365-commerce/retail-proxy/dist/interfaces/ITelemetry';

//==============================================================================
// CONSTANTS
//==============================================================================
const COOKIE_NAME = 'd365_catalog';

declare var ___initialData___: any;

//==============================================================================
// CLASS
//==============================================================================
class CatalogCookie extends React.PureComponent<ICatalogCookieProps<ICatalogCookieData>> {

    //----------------------------------------------------------
    //----------------------------------------------------------
    constructor(props: ICatalogCookieProps<ICatalogCookieData>) {
        super(props);

        // TEMPORARY DISABLE
        const shutoff = true;
        if (shutoff) {
            return;
        }

        // Even if this is basically the first module on the page and occurs during SSR, it won't catch everything
        this.catalogManagement();
    }

    //----------------------------------------------------------
    // This module doesn't display anything, but render() is required
    //----------------------------------------------------------
    public render(): JSX.Element | null {
        return null;
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private catalogManagement() {
        const context = this.props.context.request;

        // AI logging test. It seems only warning and above makes it to AI (as exceptions).
        const log = (msg: string) => this.props.context.telemetry.log(LogLevel.Warning, msg, {values: []});

        // Only do something if the user is logged in
        if (context.user && (context.user.isB2b || context.user.isOBORequest)) {

            // Attempt to get catalog values from both the cookie and URL
            const cookieId = context.cookies.get<string>(COOKIE_NAME);      // This may even work on the server
            const urlId = context.query && context.query.catalogid;         // This should work on the server

            // If catalog is in the URL, that takes precedence. Update the cookie.
            if (urlId) {
                log(`Set catalog from URL: ${urlId}`);
                context.cookies.set<string>(COOKIE_NAME, urlId);        // Hopefully this will default to a session cookie
                context.user.catalogId = urlId;
            }
            // If no URL is set and the cookie is set, use that
            else if (cookieId?.value) {
                log(`Set catalog from cookie: ${urlId}`);
                context.user.catalogId = cookieId.value;
            }
            else if (context.user.catalogId && Number(context.user.catalogId) > 0) {
                log(`Catalog already set: ${context.user.catalogId}`);
            }
            else {
                log(`Unable to set catalog ID`);
            }
        }
    }
}

export default CatalogCookie;
